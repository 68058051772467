import { useMemo, useEffect, useState } from 'react';
import { useVenues } from '../organization/useVenues';
import { sessionSubject } from '../../state/sessionObservable';
import { getSession } from '../../state/preferences';

export enum SubNavigatorName {
  Login = 'Login',
  AssignPos = 'AssignPos',
  Cart = 'CartNavigator',
  Idle = 'LoginIdle',
}

// Check session valid by making a request
// if there is no token or valid session: UNAUTHENTICATE error will throw
// if there is valid token: the request success and redirect user to cart navigator
// if there is expired token but a valid refresh token: the request success and redirect user to cart navigator
// if there is expired both token and refresh token: UNAUTHENTICATE error will throw
export const useInitialScreen = () => {
  const { loading, error } = useVenues();
  const [isValidSession, setValidSession] = useState(false);

  useEffect(() => {
    getSession().then(session => {
      if (session) {
        sessionSubject.next(session);
        if (!session.customerDisplay) {
          setValidSession(false);
        } else {
          setValidSession(true);
        }
      }
    });
  }, []);

  const screen = useMemo(() => {
    if (loading) return SubNavigatorName.Idle;
    if (error) return SubNavigatorName.Login;
    if (!isValidSession) return SubNavigatorName.AssignPos;
    return SubNavigatorName.Cart;
  }, [loading, error, isValidSession]);

  return {
    screen,
  };
};
