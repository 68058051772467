import { gql } from '@apollo/client';
export const SYNC_ORDER_EVENTS = gql`
  mutation syncEvents($input: [EventInput!]) {
    syncEvents(input: $input)
  }
`;
export const SYNC_CDS_ORDER_EVENTS = gql`
  mutation syncCdsEvents($input: [EventInput!]) {
    syncCdsEvents(input: $input)
  }
`;
