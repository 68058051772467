import {
  OrderPaymentEvent,
  OrderPaymentProcessedEvent,
  OrderPaymentStatus,
} from '@oolio-group/domain';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useCallback } from 'react';
import { CARD_PAYMENT_TYPE } from '../../common/const';
import { ScreenName } from '../../common/enum';

export const useCartNavigationHelper = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<StackNavigationProp<any>>();

  const navigateIdleScreen = useCallback(() => {
    navigation.navigate(ScreenName.IDLE);
  }, [navigation]);

  const navigatePaymentScreen = useCallback(
    (event: OrderPaymentEvent) => {
      if (event.refundOrder) return;
      if (
        (event as OrderPaymentEvent).paymentTypeName.toLowerCase() !==
        CARD_PAYMENT_TYPE
      ) {
        navigation.navigate(ScreenName.PAYMENT, {
          error: false,
          awaiting: false,
          changeDue: (event as OrderPaymentEvent).change,
          amount: (event as OrderPaymentEvent).tendered,
        });
      } else {
        navigation.navigate(ScreenName.PAYMENT, {
          error: false,
          awaiting: true,
          amount: (event as OrderPaymentEvent).tendered,
        });
      }
    },
    [navigation],
  );

  const navigatePaymentResultScreen = useCallback(
    (event: OrderPaymentProcessedEvent, paymentRequestId: string) => {
      if (
        event.paymentStatus === OrderPaymentStatus.REJECTED ||
        event.paymentStatus === OrderPaymentStatus.CANCELLED
      ) {
        navigation.navigate(ScreenName.PAYMENT, {
          error: true,
          awaiting: false,
          paymentRequestId: paymentRequestId,
        });
      } else if (event.paymentStatus === OrderPaymentStatus.COMPLETE) {
        navigation.navigate(ScreenName.PAYMENT, {
          error: false,
          awaiting: false,
          paymentRequestId: paymentRequestId,
        });
      }
    },
    [navigation],
  );

  const navigateCartScreen = useCallback(() => {
    navigation.navigate(ScreenName.CART);
  }, [navigation]);

  const navigateInputInfoScreen = useCallback(
    (type: string) => {
      navigation.navigate(ScreenName.INPUT_INFO, { type });
    },
    [navigation],
  );

  return {
    navigateIdleScreen,
    navigatePaymentScreen,
    navigateCartScreen,
    navigatePaymentResultScreen,
    navigateInputInfoScreen,
  };
};
