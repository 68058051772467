import React, { useState } from 'react';
import {
  Text,
  View,
  ViewStyle,
  TextInput,
  StyleSheet,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import {
  Control,
  Controller,
  useWatch,
  FieldPath,
  FieldValues,
} from 'react-hook-form';
import theme from '../../../common/theme';
import Icon from '../../Icon/Icon';
import { countries } from 'countries-list';
import { usePhoneNumber } from '@oolio-group/localization';
import { isValidNumber } from '../../../utils/validator';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import { useTranslation } from '@oolio-group/localization';

export interface FormPhoneInputProps<T extends FieldValues> {
  testID?: string;
  containerStyle?: ViewStyle | ViewStyle[];
  control: Control<T>;
  name: FieldPath<T>;
  placeholder?: string;
  selectedCountry: string;
  onChangeCountry: (value: string) => void;
}

const styles = StyleSheet.create({
  inputContainer: {
    height: 44,
    borderRadius: 6,
    paddingRight: 12,
    paddingLeft: 44,
    display: 'flex',
    flex: 1,
  },
  inputTitleText: {
    marginBottom: 6,
    paddingLeft: 12,
    color: theme.colors.grey6,
  },
  errorText: {
    paddingTop: 6,
    paddingLeft: 12,
    color: theme.colors.red,
    fontSize: theme.fontSize.small,
  },
  accessory: {
    position: 'absolute',
    right: 0,
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnFlag: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
  flag: {
    fontSize: 20,
  },
  options: {
    width: 280,
    ...theme.shadow.light,
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.white,
  },
  option: {
    padding: 12,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderBottomColor: theme.colors.grey3,
  },
  phoneInputContainer: {
    display: 'flex',
    width: '100%',
  },
  phoneInput: {
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 6,
    borderColor: theme.colors.grey3,
  },
});

const FormPhoneInput = <T extends FieldValues>({
  control,
  name,
  placeholder,
  containerStyle,
  selectedCountry,
  onChangeCountry,
  ...props
}: FormPhoneInputProps<T>) => {
  const { translate } = useTranslation();
  const invalidPhoneMessage = translate(
    'cdsInputInformation.invalidPhoneMessage',
  );
  const phoneNumber = useWatch({
    control,
    name,
  });
  const { getFullFormattedPhoneNumber } = usePhoneNumber();
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const isPhoneValid = isValidNumber(
    getFullFormattedPhoneNumber(selectedCountry, String(phoneNumber)),
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: () => isPhoneValid,
      }}
      render={({ field: { onChange }, formState: { isDirty, isValid } }) => (
        <View style={[styles.phoneInputContainer, containerStyle]}>
          <View style={styles.phoneInput}>
            <Popover
              isVisible={showOptions}
              verticalOffset={6}
              popoverStyle={styles.options}
              onRequestClose={() => setShowOptions(false)}
              placement={PopoverPlacement.BOTTOM}
              from={
                <TouchableOpacity
                  style={styles.btnFlag}
                  onPress={() => setShowOptions(!showOptions)}
                >
                  <Text style={styles.flag}>
                    {countries[selectedCountry]?.emoji || '?'}
                  </Text>
                </TouchableOpacity>
              }
            >
              <FlatList
                data={Object.keys(countries)}
                style={styles.options}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    style={styles.option}
                    onPress={() => {
                      onChangeCountry(item);
                      setShowOptions(false);
                    }}
                  >
                    <Text>
                      {countries[item].emoji + '  ' + countries[item].name}
                    </Text>
                  </TouchableOpacity>
                )}
              />
            </Popover>
            <TextInput
              value={phoneNumber}
              onChangeText={onChange}
              keyboardType="phone-pad"
              placeholder={placeholder}
              placeholderTextColor={theme.colors.grey5}
              style={styles.inputContainer}
              autoCapitalize="none"
              autoComplete="tel"
              autoCorrect={false}
              {...props}
            />
            <View style={styles.accessory}>
              {phoneNumber ? (
                <Icon
                  size={20}
                  color={
                    isPhoneValid
                      ? theme.colors.states.positive
                      : theme.colors.states.negative
                  }
                  name={isPhoneValid ? 'UilCheck' : 'UilExclamationTriangle'}
                />
              ) : undefined}
            </View>
          </View>
          {isDirty && !isValid && (
            <Text style={styles.errorText}>{invalidPhoneMessage}</Text>
          )}
        </View>
      )}
    />
  );
};

export default FormPhoneInput;

FormPhoneInput.defaultProps = {
  editable: true,
};
