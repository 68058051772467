import React from 'react';
import {
  View,
  ViewStyle,
  TouchableOpacity,
  GestureResponderEvent,
} from 'react-native';
import Icon from '../Icon/Icon';
import { getContainerStyle, getIconColor } from './Buttons.styles';

interface ButtonIconProps {
  testID?: string;
  icon: string;
  size?: 34 | 38 | 44;
  type?:
    | 'primary'
    | 'primaryLight'
    | 'positive'
    | 'negative'
    | 'neutral'
    | 'positiveLight'
    | 'negativeLight'
    | 'neutralLight'
    | 'focus'
    | 'focusLight'
    | 'cancel'
    | 'interface';
  onPress?: (event?: GestureResponderEvent) => void;
  containerStyle?: ViewStyle | ViewStyle[];
  disabled?: boolean;
}

const ButtonIcon: React.FC<ButtonIconProps> = ({
  testID,
  icon,
  size = 34,
  type,
  onPress,
  containerStyle,
  disabled,
}: ButtonIconProps) => {
  return (
    <View style={containerStyle}>
      <TouchableOpacity
        testID={testID}
        onPress={onPress}
        disabled={disabled}
        style={[
          getContainerStyle(!disabled ? type : 'disabled', true),
          { width: size, height: size },
        ]}
      >
        <Icon
          name={icon}
          size={20}
          color={getIconColor(!disabled ? type : 'disabled')}
        />
      </TouchableOpacity>
    </View>
  );
};

export default ButtonIcon;
