import { useApolloClient, useSubscription } from '@apollo/client/react/hooks';
import { useNavigation } from '@react-navigation/native';
import { Session } from '../../state/Session';
import { useCallback } from 'react';
import { DeviceEventActions } from '@oolio-group/domain';
import { DeviceEvents } from '../../graphql/subscription';
import { sessionSubject } from '../../state/sessionObservable';
import useBehaviorSubjectState from '../rxjs/useSubjectState';
import { SubNavigatorName } from '../login/useInitialScreen';
import { setSession } from '../../state/preferences';

export const useDeviceEvent = () => {
  const { value: session, setValue } = useBehaviorSubjectState(sessionSubject);
  const client = useApolloClient();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<any>();

  const logout = useCallback(() => {
    setSession({} as Session);
    setValue({} as Session);
    client.cache.reset();
    navigation.navigate(SubNavigatorName.Login);
  }, [navigation, setValue, client]);

  useSubscription<{
    deviceEvents: {
      deviceId: string;
      storeId: string;
      organizationId: string;
      action: string;
    }[];
  }>(DeviceEvents, {
    variables: {
      storeId: session.currentStore?.id,
      deviceId: session.device?.id,
    },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.deviceEvents?.[0]) {
        const event = subscriptionData.data?.deviceEvents?.[0];
        switch (event.action) {
          case DeviceEventActions.UN_PAIR_CDS_ACTION:
            logout();
            break;

          default:
            break;
        }
      }
    },
  });
};
