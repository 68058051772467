/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@apollo/client/react/hooks';
import { OrderEvent } from '@oolio-group/domain';
import { useCallback, useMemo } from 'react';
import { SYNC_ORDER_EVENTS } from '../../graphql/syncEvents';
import { dedupeOrderEvents } from '@oolio-group/order-helper';

export function useSyncOrderEvents(
  postCompleteSync?: () => void,
  postErrorSyncPost?: () => void,
) {
  const onErrorSync = useCallback(() => {
    postErrorSyncPost && postErrorSyncPost();
  }, [postErrorSyncPost]);

  const onCompletedSync = useCallback(
    (data: any) => {
      if (data?.syncEvents) {
        postCompleteSync && postCompleteSync();
      }
    },
    [postCompleteSync],
  );

  const [syncEvents] = useMutation(SYNC_ORDER_EVENTS, {
    onError: onErrorSync,
    onCompleted: onCompletedSync,
  });

  const syncOrderEvents = useCallback(
    (events: OrderEvent[]) => {
      const uniqueEvents = dedupeOrderEvents(events);
      syncEvents({ variables: { input: uniqueEvents } });
    },
    [syncEvents],
  );

  return useMemo(
    () => ({
      syncOrderEvents: syncOrderEvents,
    }),
    [syncOrderEvents],
  );
}
