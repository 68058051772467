import { gql } from '@apollo/client';

export const orderEventFragment = `
  __typename
  ... on InitiateOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderTypeId
    orderNumber
    salesChannelId
    integrationInfo {
      app
      id
    }
    status
  }
  ... on AssignTableEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    guestCount
    tableId
    tableName
    sectionId
    sectionName
  }
  ... on AssignOrderTypeEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderTypeId
    orderTypeName
  }
  ... on AddOrderItemEvent {
    id
    action
    costPrice
    deviceId
    orderId
    organizationId
    previous
    productId
    productName
    quantity
    taxInclusive
    storeId
    taxes {
      id
      name
      code
      rate
    }
    timestamp
    triggeredBy
    unitPrice
    venueId
    fireItem
    optionalSeatNumber: seatNumber
    courseId
    inventoryTracked
    modifiers {
      quantity
      unitPrice
      taxes {
        id
        name
        code
        rate
      }
      id
      name
      modifierGroupId
    }
    variantId
    variantName
    optionValues {
      parentOptionId
      key
      value
    }
    alternateNames {
      locale
      display
      kitchen
      receipt
      description
    }
  }
  ... on AssignOrderNameEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    orderName
    timestamp
    previous
  }
  ... on SaveOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }
  ... on UpdateOrderNotesEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    notes
  }
  ... on UpdateFulfilmentDetail {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    shippingAddress {
      line1
      line2
      postalCode
      state
      isoCountryCode
      city
      country
    }
    requiredAt
  }
  ... on AssignCustomerEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    customerId
    firstName
    lastName
    email
    phone
    loyaltyMember
    customerAccountDetails {
      currentBalance
      accountPayment
      maxBalanceLimit
      maxOrderLimit
    }
  }

  ... on UpdateCustomerEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    loyaltyMember
    isLoyaltyApplied
  }

  ... on UnassignCustomerEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }

  ... on UnassignTableEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }

  ... on VoidOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    voidReason: reason
    productQuantities {
      id
      quantity
    }
    inventoryTracked
  }
  ... on RemoveOrderItemEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemId
    optionalVoidReason: reason
    quantity
    inventoryTracked
    productId
  }
  ... on UpdateOrderItemPriceEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemId
    adjustments {
      id
      name
      amount
      adjustmentUnit
      allowOnPaymentType
      adjustmentType
    }
    unitPrice
  }
  ... on UpdateOrderItemQuantityEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemId
    quantity
    optionalVoidReason: reason
    inventoryTracked
    productId
  }
  ... on AddOrderDiscountEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    discount {
      amount
      type
    }
  }
  ... on AddModifierEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    unitPrice
    quantity
    taxes {
      id
      name
      code
      rate
    }
    name
    modifierId
    modifierGroupId
    modifierGroupPriority
    orderItemId
    alternateNames {
      locale
      display
      kitchen
      receipt
      description
    }
  }
  ... on UpdateModifierQuantityEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemId
    modifierId
    modifierGroupId
    quantity
  }
  ... on RemoveModifierEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    optionalOrderItemId: orderItemId
    modifierId
    modifierGroupId
  }
  ... on AddOrderItemNotesEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemId
    note
  }
  ... on OrderPaymentEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    paymentTypeId
    tendered
    change
    tip
    roundOffDifference
    refundOrder
    onAccount
    optionalCustomerId: customerId
    paymentTypeName
    optionalPaymentRequestId: paymentRequestId
    optionalPaymentStatus: paymentStatus
    orderItemIds
    customer
    splitPaymentBy
    paymentSurcharge
  }
  ... on OrderPaymentProcessedEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    paymentStatus
    paymentRequestId
    paymentTransactionRef
    paymentCompletedAt
    paymentReceipt
    paymentSurcharge
    cardType
    paymentTip
  }
  ... on InitiateRefundEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    refundOf
    refundOrderNumber
    reason
  }
  ... on OrdersRefetchEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }
  ... on RefundOrderItemsEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItems {
      id
      quantity
    }
  }
  ... on RefundOrderAmountEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    amount
  }
  ... on AssignRefundReasonEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    reason
  }
  ... on HoldOrderItemEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    optionalReason: reason
  }
  ... on HoldOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }
  ... on RemoveHoldOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }
  ... on FireOrderItemsEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemsToFire {
      id
      status
    }
  }
  ... on PrintKitchenDocketEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }

  ... on UpdateSeatNumberEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    seatNumber
    orderItemIds
  }
  ... on SwitchCourseItemEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemId
    courseId
  }

  ... on InitiateOnlineOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    source
    order
  }

  ... on AcceptOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }
  ... on CompleteOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }
  ... on RejectOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    rejectionReason: reason
  }
  ... on PartnerCancelOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }
  ... on AddOrderAdjustmentEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    adjustment {
      id
      name
      amount
      adjustmentUnit
      allowOnPaymentType
      adjustmentType
    }
    productId
  }
  ... on UpdateOrderAdjustmentsEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    adjustments {
      id
      name
      amount
      adjustmentUnit
      allowOnPaymentType
      adjustmentType
      doNotIncludeInSalesAmount
    }
  }
  ... on UpdateOrderItemsAdjustmentEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    adjustment {
      id
      name
      amount
      adjustmentUnit
      allowOnPaymentType
      adjustmentType
      doNotIncludeInSalesAmount
      isDiscountRuleEnabled
      quantity
    }
    orderItemIds
  }
  ... on RemoveOrderAdjustmentEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    adjustmentType
    adjustmentIds
    orderItemAdjustmentIds
    orderItemIds
  }
  ... on UpdateOrderLastCheckEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    lastCheck
  }
  ... on PrintOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    print
    printerId
    printType
    purpose
  }
  ... on OrderPlacedEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }
  ... on OrderInitiateMergeEvent {
    id
    action
    triggeredBy
    timestamp
    previous
    venueId
    storeId
    deviceId
    organizationId
    orderId
    source
    orderIdsToMerge
    itemMergeOptions
  }
  ... on OrderMergedEvent {
    id
    action
    triggeredBy
    timestamp
    previous
    venueId
    storeId
    deviceId
    organizationId
    orderId
    mergedInto
  }
  ... on OrderCloseEvent {
    id
    action
    triggeredBy
    timestamp
    previous
    venueId
    storeId
    deviceId
    organizationId
    orderId
    status
  }
  ... on OrderOpenEvent {
    id
    action
    triggeredBy
    timestamp
    previous
    venueId
    storeId
    deviceId
    organizationId
    orderId
  }
  ... on AddRewardItemEvent {
    id
    action
    triggeredBy
    timestamp
    previous
    venueId
    storeId
    deviceId
    organizationId
    orderId
    rewardId
    rewardName
    rewardType
    pointsRequired
    discountAmount
    discountType
    maximumDiscountAmount
    quantity
    productId
  }
  ... on UpdateRewardItemEvent {
    id
    action
    triggeredBy
    timestamp
    previous
    venueId
    storeId
    deviceId
    organizationId
    orderId
    rewardId
    quantity
    productId
  }
  ... on RemoveRewardItemEvent {
    id
    action
    triggeredBy
    timestamp
    previous
    venueId
    storeId
    deviceId
    organizationId
    orderId
    rewardId
    productId
  }
  ... on ResetRewardItemEvent {
    id
    action
    triggeredBy
    timestamp
    previous
    venueId
    storeId
    deviceId
    organizationId
    orderId
  }
  ... on AddOrderLoyaltySnapshotEvent {
    id
    action
    triggeredBy
    timestamp
    previous
    venueId
    storeId
    deviceId
    organizationId
    orderId
    loyaltySnapshot
  }
  ... on UpdateTipEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    amount
  }
  ... on AddTipEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    amount
    staffId
  }
  ... on CustomerNotificationEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    notificationMode
    notificationType
    message
    email
    phone
  }
  ... on SplitQuantityOrderItemEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemId
    splitOrderItemId
    quantity
  }
  ... on AddComboEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    optionalOrderItemId: orderItemId
    comboItems {
      id
      productId
      productName
      printerProfiles
      modifierGroupId
      quantity
      unitPrice
      productOriginalPrice
      variantId
      variantName
      note
      modifiers {
        quantity
        unitPrice
        id
        name
        modifierGroupId
      }
    }
  }
  ... on OrderTransferredItemsEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    transferredItemsFrom {
      orderId
      orderItems
      tableId
    }
    transferredItemsTo {
      orderId
      tableId
    }
    transferredByName
  }
  ... on OrderTransferItemsEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    transferItemsFrom {
      orderId
      orderItems
      tableId
    }
    transferItemsTo {
      orderId
      tableId
    }
    transferredByName
  }
`;

export const CDS_ORDER_EVENT = gql`
  subscription CdsOrderEvents(
    $cdsId: String!
    $deviceId: String!
    $preEventId: String
    $orderId: String
    $lastActiveTime: Float
  ) {
    cdsOrderEvents(
      cdsId: $cdsId
      deviceId: $deviceId
      preEventId: $preEventId
      orderId: $orderId
      lastActiveTime: $lastActiveTime
    ) {
      ${orderEventFragment}
    }
  }
`;

export const DeviceEvents = gql`
  subscription DeviceEvents($storeId: String!, $deviceId: String!) {
    deviceEvents(storeId: $storeId, deviceId: $deviceId) {
      deviceId
      storeId
      organizationId
      action
    }
  }
`;
