import { gql } from 'graphql-tag';

export const FRAGMENT_ORGANIZATION_DETAILS = gql`
  fragment OrganizationDetails on Organization {
    id
    name
    businessIdentifier
    country
    currencyCode
  }
`;

export const GET_ORGANIZATION_DETAILS = gql`
  query organization($orgId: String!) {
    getOrganizationById(id: $orgId) {
      ...OrganizationDetails
    }
  }
  ${FRAGMENT_ORGANIZATION_DETAILS}
`;
