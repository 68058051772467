import {
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  FetchRequestor,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenRequest,
  TokenResponse,
} from '@openid/appauth';
import config from '../config';
import { Session } from '../state/Session';
import * as settings from '../state/preferences';
import { sessionSubject } from '../state/sessionObservable';

export const refreshTokenAccessToken = async () => {
  const session = (await settings.getSession()) as Session;
  const tokenRequest = new TokenRequest({
    client_id: config.auth0.clientId,
    redirect_uri: config.auth0.redirectUrl,
    grant_type: GRANT_TYPE_REFRESH_TOKEN,
    refresh_token: session?.refreshToken,
  });
  const issuerConfig = await AuthorizationServiceConfiguration.fetchFromIssuer(
    config.auth0.issuer || '',
    new FetchRequestor(),
  );
  const response = (await new BaseTokenRequestHandler(
    new FetchRequestor(),
  ).performTokenRequest(
    issuerConfig,
    tokenRequest,
  )) as unknown as Promise<Response>;

  const { accessToken, refreshToken, expiresIn } =
    response as unknown as TokenResponse;

  const newTokenPayload: Session = {
    ...session,
    token: accessToken as string,
    refreshToken: refreshToken as string,
    expiredDate: (expiresIn as number) * 1000,
  };
  sessionSubject.next(newTokenPayload);
  await settings.setSession(newTokenPayload);
  return { accessToken };
};
