import { Order } from '@oolio-group/domain';
import React, { createContext, useContext } from 'react';
import useCartEvent from './useCartEvent';

export interface CartProviderValue {
  order: Order;
}
export interface CartProviderProps {
  children: React.ReactNode;
}

export const CartContext = createContext<CartProviderValue>({
  order: {} as Order,
});

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const cart = useCartEvent();
  return <CartContext.Provider value={cart}>{children}</CartContext.Provider>;
};

export const useCart = (): CartProviderValue => {
  return useContext(CartContext);
};
