import { StyleSheet } from 'react-native';
import theme from '../../common/theme';

const styles = StyleSheet.create({
  innerView: {
    flex: 1,
    padding: theme.spacing.medium,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  paymentInfo: {
    flex: 1,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.large,
    backgroundColor: theme.colors.states.positive,
  },
  title: {
    lineHeight: 26,
    fontFamily: theme.fonts.semibold,
    color: theme.colors.white,
    fontSize: theme.fontSize.large,
    marginTop: theme.spacing.small,
  },
  amountPaid: {
    lineHeight: 26,
    fontFamily: theme.fonts.semibold,
    color: theme.colors.white,
    fontSize: theme.fontSize.large,
    marginTop: theme.spacing.small,
    opacity: 0.6,
  },
  changeDue: {
    lineHeight: 26,
    fontFamily: theme.fonts.semibold,
    color: theme.colors.white,
    fontSize: theme.fontSize.large,
    marginTop: theme.spacing.xl,
  },
  price: {
    fontSize: 48,
    marginTop: theme.spacing.large,
    fontFamily: theme.fonts.semibold,
    color: theme.colors.white,
  },
  receiptOptions: {
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  receiptOptionsBox: {
    flex: 1,
    flexDirection: 'column',
    width: 300,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  receiptOptionsTitle: {
    fontSize: 28,
    fontFamily: theme.fonts.bold,
    color: theme.colors.white,
    textAlign: 'center',
  },
  btnSmsOption: {
    marginTop: 40,
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.white,
    width: '100%',
    paddingVertical: 18,
    paddingHorizontal: 20,
  },
  btnEmailOption: {
    marginTop: 20,
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.white,
    width: '100%',
    paddingVertical: 17,
    paddingHorizontal: 20,
  },
  btnNoReceipt: {
    marginTop: 60,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'row',
    textAlign: 'center',
  },
  optionText: {
    lineHeight: 26,
    fontFamily: theme.fonts.semibold,
    color: theme.colors.black1,
    fontSize: theme.fontSize.large,
    textAlign: 'center',
  },
  noReceiptText: {
    lineHeight: 26,
    fontFamily: theme.fonts.semibold,
    color: theme.colors.white,
    fontSize: theme.fontSize.large,
  },
});

export default styles;
