import {
  CloseOrderEvent,
  OrderAction,
  OrderEvent,
  OrderPaymentEvent,
  OrderPaymentProcessedEvent,
  OrderStatus,
} from '@oolio-group/domain';
import { useFocusEffect } from '@react-navigation/native';
import { unionBy } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BehaviorSubject, observeOn, queueScheduler } from 'rxjs';
import { ScreenName } from '../../common/enum';
import { cdsOrderEventsSubject } from '../cart/cdsOrderEventsObservable';
import { globalIntervalObserver } from '../interval/useGlobalInterval';
import useBehaviorSubjectState from '../rxjs/useSubjectState';
import { useCartNavigationHelper } from './useCartNavigationHelper';

const NO_EVENT_IDLE_TIME = 1000 * 60 * 3;
const PAYMENT_TIME_OUT = 1000 * 30;

export const cartActiveScreenObservable = new BehaviorSubject<{
  screen: ScreenName;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  param?: Record<string, any>;
}>({ screen: ScreenName.IDLE });

export const useCartSetActiveScreen = (screen: ScreenName, param?: Object) => {
  useFocusEffect(
    useCallback(() => {
      cartActiveScreenObservable.next({ screen, param });
    }, [screen, param]),
  );
};

const getLatestInfoEvent = (events: OrderEvent[]) =>
  [...events].reverse().find(event => event.action !== OrderAction.ORDER_SAVE);

export const useCartSwitcherEffect = () => {
  const {
    navigateIdleScreen,
    navigatePaymentScreen,
    navigateCartScreen,
    navigatePaymentResultScreen,
  } = useCartNavigationHelper();
  const { value: globalInterval } = useBehaviorSubjectState(
    globalIntervalObserver,
  );
  const activeRoute = useRef<{
    screen: ScreenName;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    param?: Record<string, any>;
  }>();
  const cartScreenFocusTimestamp = useRef(Date.now());
  const paymentAwaitingScreenFocusTimestamp = useRef(Date.now());
  const lastProcessedEventId = useRef<string>();
  const recentPaymentEvent = useRef<OrderPaymentEvent>();
  const eventsByOrderId = useRef<OrderEvent[]>([]);
  const [orderEvents, setOrderEvents] = useState<OrderEvent[]>([]);

  const clearEventState = useCallback(() => {
    eventsByOrderId.current = [];
    recentPaymentEvent.current = undefined;
    lastProcessedEventId.current = undefined;
    setOrderEvents([]);
  }, []);

  useEffect(() => {
    const sub = cartActiveScreenObservable.subscribe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (route: { screen: ScreenName; param?: Record<string, any> }) => {
        activeRoute.current = route;
        switch (route.screen) {
          case ScreenName.CART:
            cartScreenFocusTimestamp.current = Date.now();
            break;

          case ScreenName.PAYMENT:
            if (route.param?.awaiting)
              paymentAwaitingScreenFocusTimestamp.current = Date.now();
            break;

          default:
            break;
        }
      },
    );
    return () => sub?.unsubscribe?.();
  }, []);

  useEffect(() => {
    const eventSub = cdsOrderEventsSubject
      .pipe(observeOn(queueScheduler))
      .subscribe((events: OrderEvent[]) => setOrderEvents(events));
    return () => eventSub?.unsubscribe?.();
  }, []);

  const screenSwitcherOnOrderEvents = useCallback(
    (orderEvents: OrderEvent[], currentInterval: number) => {
      let latestEvent: OrderEvent | undefined;
      const newOrderId = orderEvents?.[0]?.orderId;
      const currentOrderId = eventsByOrderId.current?.[0]?.orderId;

      if (newOrderId && currentOrderId && newOrderId !== currentOrderId) {
        eventsByOrderId.current = orderEvents;
      } else if (newOrderId) {
        eventsByOrderId.current = unionBy(
          eventsByOrderId.current,
          orderEvents,
          'id',
        ).sort((a, b) => a.timestamp - b.timestamp); // Async in pos, events, subscription causing cds sometime receive the older event after the later event
      }

      let latestEventByTime;
      switch (activeRoute.current?.screen) {
        case ScreenName.IDLE:
          latestEvent = getLatestInfoEvent(eventsByOrderId.current);

          if (!latestEvent) break;
          if (latestEvent.id === lastProcessedEventId.current) break;

          lastProcessedEventId.current = latestEvent?.id;
          switch (latestEvent.action) {
            case OrderAction.ORDER_VOID:
            case OrderAction.ORDER_INITIATE:
            case OrderAction.ORDER_REFUND:
            case OrderAction.ORDER_REFUND_INITIATE:
            case OrderAction.ORDER_REFUND_ITEMS:
            case OrderAction.ORDER_REFUND_AMOUNT:
            case OrderAction.ORDER_REFUND_PAYMENT:
            case OrderAction.ORDER_PRINT_KITCHEN_DOCKET:
            case OrderAction.ORDER_PRINT:
            case OrderAction.ORDER_ASSIGN_REFUND_REASON:
              break;

            case OrderAction.ORDER_PAYMENT:
              recentPaymentEvent.current = latestEvent as OrderPaymentEvent;
              navigatePaymentScreen(latestEvent as OrderPaymentEvent);
              break;

            case OrderAction.ORDER_PAYMENT_PROCESSED:
              navigatePaymentResultScreen(
                latestEvent as OrderPaymentProcessedEvent,
                (latestEvent as OrderPaymentProcessedEvent).paymentRequestId,
              );
              break;

            default:
              navigateCartScreen();
              break;
          }
          break;

        case ScreenName.CART:
          latestEventByTime = orderEvents.reduce(
            (preEvent, event) =>
              event.action !== OrderAction.ORDER_SAVE &&
              preEvent.timestamp < event.timestamp
                ? event
                : preEvent,
            {
              timestamp: cartScreenFocusTimestamp.current,
              action: '',
            } as unknown as OrderEvent,
          );
          if (
            currentInterval - latestEventByTime.timestamp >
            NO_EVENT_IDLE_TIME
          ) {
            navigateIdleScreen();
            break;
          }

          latestEvent = getLatestInfoEvent(eventsByOrderId.current);
          if (!latestEvent) break;
          if (latestEvent.id === lastProcessedEventId.current) break;

          lastProcessedEventId.current = latestEvent?.id;
          switch (latestEvent.action) {
            case OrderAction.ORDER_CLOSE:
              if (
                (latestEvent as CloseOrderEvent).status !==
                OrderStatus.IN_PROGRESS
              ) {
                navigateIdleScreen();
                clearEventState();
              }
              break;

            case OrderAction.ORDER_PAYMENT:
              recentPaymentEvent.current = latestEvent as OrderPaymentEvent;
              navigatePaymentScreen(latestEvent as OrderPaymentEvent);
              break;

            case OrderAction.ORDER_PAYMENT_PROCESSED:
              navigatePaymentResultScreen(
                latestEvent as OrderPaymentProcessedEvent,
                (latestEvent as OrderPaymentProcessedEvent).paymentRequestId,
              );
              break;

            case OrderAction.ORDER_REFUND:
            case OrderAction.ORDER_INITIATE:
            case OrderAction.ORDER_REFUND_INITIATE:
            case OrderAction.ORDER_REFUND_ITEMS:
            case OrderAction.ORDER_REFUND_AMOUNT:
            case OrderAction.ORDER_REFUND_PAYMENT:
            case OrderAction.ORDER_ASSIGN_REFUND_REASON:
              navigateIdleScreen();
              clearEventState();
              break;

            default:
              break;
          }
          break;

        case ScreenName.PAYMENT:
          if (activeRoute.current.param?.['awaiting']) {
            latestEventByTime = orderEvents.reduce(
              (preEvent, event) =>
                event.action !== OrderAction.ORDER_SAVE &&
                preEvent.timestamp < event.timestamp
                  ? event
                  : preEvent,
              {
                timestamp: paymentAwaitingScreenFocusTimestamp.current,
                action: '',
              } as unknown as OrderEvent,
            );
            if (
              currentInterval - latestEventByTime.timestamp >
              PAYMENT_TIME_OUT
            ) {
              navigateIdleScreen();
              break;
            }
          }

          latestEvent = getLatestInfoEvent(eventsByOrderId.current);

          if (!latestEvent) break;
          if (latestEvent.id === lastProcessedEventId.current) {
            break;
          }

          lastProcessedEventId.current = latestEvent?.id;
          switch (latestEvent.action) {
            case OrderAction.ORDER_PAYMENT_PROCESSED:
              navigatePaymentResultScreen(
                latestEvent as OrderPaymentProcessedEvent,
                (latestEvent as OrderPaymentProcessedEvent).paymentRequestId,
              );
              break;

            case OrderAction.ORDER_PAYMENT:
              recentPaymentEvent.current = latestEvent as OrderPaymentEvent;
              navigatePaymentScreen(latestEvent as OrderPaymentEvent);
              break;

            case OrderAction.ORDER_INITIATE:
              break;

            case OrderAction.ORDER_OPEN:
              navigateCartScreen();
              break;

            case OrderAction.ORDER_CLOSE:
            case OrderAction.ORDER_REFUND:
            case OrderAction.ORDER_REFUND_INITIATE:
            case OrderAction.ORDER_REFUND_ITEMS:
            case OrderAction.ORDER_REFUND_AMOUNT:
            case OrderAction.ORDER_REFUND_PAYMENT:
            case OrderAction.ORDER_ASSIGN_REFUND_REASON:
            case OrderAction.ORDER_PRINT:
              activeRoute.current = { screen: ScreenName.IDLE }; // manually set this screen to workaround async issue when 2nd init event come too fast
              navigateIdleScreen();
              clearEventState();
              break;

            default:
              console.log('activeRoute.current', activeRoute.current);
              if (activeRoute.current.param?.['awaiting'] === false) {
                navigateCartScreen();
              }
              break;
          }
          break;

        default:
          break;
      }
    },
    [
      cartScreenFocusTimestamp,
      navigateIdleScreen,
      navigatePaymentScreen,
      navigateCartScreen,
      navigatePaymentResultScreen,
      clearEventState,
    ],
  );

  useEffect(() => {
    screenSwitcherOnOrderEvents(orderEvents, globalInterval);
  }, [globalInterval, orderEvents, screenSwitcherOnOrderEvents]);
};
