export const isValidEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email) && email?.length < 50;
};

export const isValidName = (name: string): boolean => {
  const re = /^(?=.*[a-zA-Z]){5,}([^0-9]*)$/g;
  return re.test(name);
};

export const isValidPhoneNumber = (number: string): boolean => {
  const re = /^[0-9]{10}$/;
  return re.test(number);
};

export const isValidNumber = (number: string): boolean => {
  const phoneSplit = (number || '').split(' ');

  if (phoneSplit.length !== 2) {
    return false;
  }

  const phoneCode = phoneSplit[0] || '';
  const schemaPhoneNumber =
    getPhoneNumberValidationSchemaByPhoneCode(phoneCode);
  const regPhone = new RegExp(schemaPhoneNumber, 'g');

  return regPhone.test(number);
};

const phoneNumberValidationSchema: { [key: string]: string } = {
  '+61': '^\\+?([0-9]{1,4})\\)?[ ]?([0-9]{9,10})$',
  default: '^\\+?([0-9]{1,4})\\)?[ ]?([0-9]{10})$',
};

export const getPhoneNumberValidationSchemaByPhoneCode = (
  phoneCountryCode = 'default',
): string => {
  return (
    phoneNumberValidationSchema[phoneCountryCode] ||
    phoneNumberValidationSchema['default']
  );
};

export const isEmpty = (value = ''): boolean =>
  value?.replace(/\s/g, '').length <= 0;
