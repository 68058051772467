import { useLazyQuery } from '@apollo/client/react/hooks';
import { Device } from '@oolio-group/domain';
import { useEffect } from 'react';
import { GET_DEVICES } from '../../graphql/devices';

export const useDevices = () => {
  const [refetch, { data, loading, error }] = useLazyQuery<{
    devices: Device[];
  }>(GET_DEVICES, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return {
    devices: data?.devices,
    loading,
    error,
    refetch,
  };
};
