import React from 'react';
import * as Unicons from '@oolio-group/react-native-unicons';
import { TextStyle } from 'react-native';
import theme from '../../common/theme';

export interface IconProps {
  name: string;
  color?: string;
  size?: number;
  style?: TextStyle;
  disabled?: boolean;
  primary?: boolean;
  success?: boolean;
  danger?: boolean;
  secondary?: boolean;
  testID?: string;
}

const Icon: React.FC<IconProps> = ({
  name,
  size,
  color,
  disabled,
  primary,
  success,
  danger,
  secondary,
  style,
}) => {
  const Component = Unicons[name];

  if (!Component) {
    return null;
  }
  return (
    <Component
      size={size}
      color={color}
      style={[
        {
          color:
            color ||
            (disabled
              ? theme.colors.grey5
              : primary
              ? theme.colors.primary
              : success
              ? theme.colors.states.positive
              : danger
              ? theme.colors.states.negative
              : secondary
              ? theme.colors.states.focus
              : theme.colors.states.neutral),
          width: size,
        },
        style,
      ]}
    />
  );
};

export default Icon;
