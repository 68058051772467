/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import { App } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import jwt_decode from 'jwt-decode';
import QrCode from 'react-qr-code';
import { useDeviceCodeLogin } from '@oolio-group/hooks';
import { SubNavigatorName } from '../../hooks/login/useInitialScreen';
import config from '../../config';
import { Session } from '../../state/Session';
import { setSession } from '../../state/preferences';
import { sessionSubject } from '../../state/sessionObservable';
import styles from './LoginScreen.styles';
import Gradient from '../../component/Gradient/Gradient';
import ButtonIcon from '../../component/Buttons/ButtonIcon';
import { useOrganization } from '../../hooks/organization/useOrganization';

const deviceCodeConfig = {
  clientId: config.auth0.clientId,
  audience: config.auth0.additionalParameters.audience,
  getTokenUrl: `${config.auth0.issuer}/oauth/token`,
  getDeviceCodeUrl: `${config.auth0.issuer}/oauth/device/code`,
};

const LoginScreen: React.FC = () => {
  const refreshQrCodeIntervalRef = useRef<any>();

  const { translate } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const {
    getDeviceCode,
    verificationUri,
    tokenPayload,
    userCode,
    expiresInRef: qrCodeTimeoutRef,
  } = useDeviceCodeLogin(deviceCodeConfig);

  useEffect(() => {
    getDeviceCode();
  }, [getDeviceCode]);

  const session = sessionSubject.value;

  const { organization } = useOrganization(
    session.currentOrganization?.id || '',
  );

  useEffect(() => {
    // refresh the QR code when it expires
    if (userCode && qrCodeTimeoutRef.current)
      refreshQrCodeIntervalRef.current = setTimeout(
        getDeviceCode,
        qrCodeTimeoutRef.current,
      );
    return () => {
      clearTimeout(refreshQrCodeIntervalRef.current);
    };
  }, [getDeviceCode, qrCodeTimeoutRef, userCode]);

  useEffect(() => {
    if (tokenPayload) {
      const idTokenPayload = jwt_decode<{
        app_metadata: any;
      }>(tokenPayload.idToken as string);
      const accesstokenPayload = jwt_decode<{
        exp: number;
      }>(tokenPayload.accessToken as string);
      const session: Session = {
        authorized: true,
        activeApp: App.CUSTOMER_DISPLAY_APP,
        token: tokenPayload.accessToken,
        refreshToken: tokenPayload.refreshToken,
        expiredDate: accesstokenPayload.exp * 1000,
        currentOrganization: {
          id: idTokenPayload.app_metadata.organization_id,
          country: organization?.country,
          currencyCode: organization?.currencyCode,
        },
      };
      setSession(session);
      sessionSubject.next(session);
      navigation.navigate(SubNavigatorName.AssignPos);
    }
  }, [
    tokenPayload,
    navigation,
    organization?.country,
    organization?.currencyCode,
  ]);

  return (
    <Gradient style={styles.screenContainer}>
      <View style={styles.screen} ph-label="login-screen">
        <View style={styles.modalContainer}>
          <Text style={styles.title}>
            {translate('deviceCodeLogin.titleCDS')}
          </Text>
          {!!verificationUri ? (
            <>
              <View style={styles.qrContainer}>
                <QrCode value={verificationUri} size={160} />
                <ButtonIcon
                  size={44}
                  icon="UilSync"
                  type="neutralLight"
                  onPress={getDeviceCode}
                  containerStyle={styles.btnRefresh}
                />
              </View>
              <View style={styles.codeContainer}>
                <Text style={styles.code}>{userCode}</Text>
              </View>
            </>
          ) : (
            <View style={styles.qrContainer}>
              <ActivityIndicator size={20} />
            </View>
          )}
          <Text style={styles.footnote}>
            {translate('deviceCodeLogin.description')}
          </Text>
        </View>
      </View>
    </Gradient>
  );
};

export default LoginScreen;
