import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Device, Organization, Store, Venue } from '@oolio-group/domain';
import { useNavigation } from '@react-navigation/native';
import { SubNavigatorName } from '../../hooks/login/useInitialScreen';
import { useDevices } from '../../hooks/organization/useDevices';
import { useVenues } from '../../hooks/organization/useVenues';
import { setSession } from '../../state/preferences';
import { sessionSubject } from '../../state/sessionObservable';
import { useCustomerDisplay } from '../../hooks/customerDisplay/useCustomerDisplay';
import { useDeviceId } from '../../hooks/deviceId/useDeviceId';
import styles from './DeviceSelection.styles';
import DeviceList from './DevicesList';
import LocationsList from './LocationsList';
import Gradient from '../../component/Gradient/Gradient';
import ButtonIcon from '../../component/Buttons/ButtonIcon';
import { Session } from '../../state/Session';
import { analyticsService } from '../../analytics/AnalyticsService';
import OrganizationsList from './OrganizationsList';
import { useSsoProfile } from '@oolio-group/hooks';
import config from '../../config';

export enum Steps {
  ORGANIZATION = 'Organization',
  STORE = 'Store',
  DEVICE = 'Device',
  PRINTER_PROFILE = 'Printer Profile',
}

const AssignPos: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<any>();
  const { deviceId: deviceUuid } = useDeviceId();
  const { user, loading: userLoading } = useSsoProfile(
    config.USERINFO_URL,
    sessionSubject.value.token as string,
  );
  const { venues, loading: venueLoading, refetch: refetchVenue } = useVenues();
  const {
    devices,
    loading: deviceLoading,
    refetch: refetchDevice,
  } = useDevices();
  const {
    createCdsOnDevice,
    customerDisplay,
    loading: createCdsLoading,
  } = useCustomerDisplay();

  const [step, setStep] = useState<Steps>(Steps.ORGANIZATION);

  const setUserToPostHog = (session: Partial<Session>) => {
    // Identify User
    analyticsService.identify(session?.currentOrganization?.id as string, {
      id: session?.currentOrganization?.id,
      name: session?.currentOrganization?.name,
      venue: session?.currentVenue?.name,
      store: session?.currentStore?.name,
    });

    // Identify Organisation
    analyticsService.group(
      'organization',
      session?.currentOrganization?.id as string,
      {
        id: session?.currentOrganization?.id,
        name: session?.currentOrganization?.name,
        venue: session?.currentVenue?.name,
        store: session?.currentStore?.name,
      },
    );
  };

  const onSelectOrganization = useCallback(
    async (organization: Pick<Organization, 'id' | 'name'>) => {
      const oldSession = sessionSubject.value;
      sessionSubject.next({
        ...oldSession,
        currentOrganization: organization,
      });
      await setSession({
        ...oldSession,
        currentOrganization: organization,
      });
      refetchVenue();
      setStep(Steps.STORE);
    },
    [refetchVenue],
  );

  const onSelectStore = useCallback(
    async (venue: Partial<Venue>, store: Store) => {
      const oldSession = sessionSubject.value;
      sessionSubject.next({
        ...oldSession,
        currentVenue: venue,
        currentStore: store,
      });
      await setSession({
        ...oldSession,
        currentVenue: venue,
        currentStore: store,
      });
      setStep(Steps.DEVICE);
      refetchDevice();
      setUserToPostHog(sessionSubject.value);
    },
    [refetchDevice],
  );

  const onSelectDevice = useCallback(
    async (device: Device) => {
      const oldSession = sessionSubject.value;
      sessionSubject.next({ ...oldSession, device: device });
      await setSession({ ...oldSession, device: device });
      createCdsOnDevice({
        deviceUuid: deviceUuid,
        deviceId: device.id,
        name: 'Default',
        isPaired: true,
      });
    },
    [createCdsOnDevice, deviceUuid],
  );

  const onBack = useCallback(() => {
    if (step === Steps.STORE) {
      navigation.navigate(SubNavigatorName.Login);
    } else {
      setStep(Steps.STORE);
    }
  }, [step, navigation]);

  useEffect(() => {
    if (!createCdsLoading && customerDisplay) {
      const oldSession = sessionSubject.value;
      setSession({ ...oldSession, customerDisplay });
      sessionSubject.next({ ...oldSession, customerDisplay });
      navigation.navigate(SubNavigatorName.Cart);
    }
  }, [createCdsLoading, customerDisplay, navigation]);

  useEffect(() => {
    switch (step) {
      case Steps.ORGANIZATION:
        if (user?.organizations?.length === 1) {
          onSelectOrganization(user.organizations[0]);
        } else if (sessionSubject.value.currentOrganization?.id) {
          setStep(Steps.STORE);
        }
        break;
      case Steps.STORE:
        if (venues?.length === 1 && venues?.[0]?.stores?.length === 1)
          onSelectStore(venues[0], venues[0].stores[0]);
        break;
      case Steps.DEVICE:
        break;
    }
  }, [step, venues, onSelectStore, onSelectOrganization, user]);

  return (
    <Gradient style={styles.screenContainer}>
      <View style={styles.screen}>
        <View style={styles.modalContainer}>
          <ButtonIcon
            icon="UilArrowLeft"
            type="cancel"
            size={44}
            onPress={onBack}
            containerStyle={styles.btnBack}
          />
          {step === Steps.ORGANIZATION && (
            <OrganizationsList
              organizations={user?.organizations ?? []}
              onSelect={onSelectOrganization}
              loading={userLoading}
            />
          )}
          {step === Steps.STORE && (
            <LocationsList
              venuesData={venues ?? []}
              onSelect={onSelectStore}
              loading={venueLoading}
            />
          )}
          {step === Steps.DEVICE && (
            <DeviceList
              onSelect={onSelectDevice}
              loading={deviceLoading || createCdsLoading}
              devices={devices ?? []}
            />
          )}
        </View>
      </View>
    </Gradient>
  );
};

export default AssignPos;
