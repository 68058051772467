import React from 'react';
import { View, Text, TextInput, ViewStyle, TextInputProps } from 'react-native';
import styles from './Search.styles';
import Icon from '../Icon/Icon';
import theme from '../../common/theme';

export interface SearchProps extends TextInputProps {
  title?: string;
  testID: string;
  placeholder?: string;
  value?: string | undefined;
  containerStyle?: ViewStyle | ViewStyle[];
}

const Search: React.FC<SearchProps> = ({
  title,
  testID,
  placeholder,
  value,
  containerStyle,
  ...props
}: SearchProps) => {
  return (
    <View style={containerStyle}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <TextInput
        value={value}
        testID={testID}
        placeholder={placeholder}
        placeholderTextColor={theme.colors.grey5}
        style={styles.inputContainer}
        autoCorrect={false}
        {...props}
      />
      <View accessibilityRole="none" style={styles.accessory}>
        <Icon size={20} color={theme.colors.grey5} name="UilSearch" />
      </View>
    </View>
  );
};

export default Search;
