/* eslint-disable @typescript-eslint/no-explicit-any */
import PostHog from 'posthog-react-native';
import { IAnalyticsProvider } from './AnalyticsService';

class PostHogProvider implements IAnalyticsProvider {
  private client: PostHog | undefined;

  async init(): Promise<void> {
    if (this.client === undefined) {
      this.client = await PostHog.initAsync(
        process.env.REACT_APP_POSTHOG_API_KEY || '',
        {
          host: 'https://app.posthog.com',
        },
      );
    }
  }

  identify(userId: string, properties: Record<string, any>): void {
    this.client?.identify(userId, properties);
  }

  group(
    groupType: string,
    groupId: string,
    properties: Record<string, any>,
  ): void {
    this.client?.group(groupType, groupId, properties);
  }

  capture(event: string, properties?: Record<string, any>): void {
    this.client?.capture(event, properties || {});
  }

  async isFeatureEnabled(feature: string): Promise<boolean> {
    return await new Promise(resolve => {
      if (this.client)
        if (this.client?.isFeatureEnabled(feature)) {
          // feature flags should be available at this point
          resolve(true);
        } else resolve(false);
      else resolve(false);
    });
  }

  async getAllFeatureFlags(): Promise<any> {
    await this.init();
    return await this.client?.reloadFeatureFlagsAsync();
  }

  startSessionRecording(): void {
    return;
  }

  stopSessionRecording(): void {
    return;
  }

  reset(): void {
    this.client?.reset();
  }
}

export default PostHogProvider;
