import { GraphQLRequest } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { App } from '@oolio-group/domain';
import jwt_decode from 'jwt-decode';
import {
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  FetchRequestor,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenRequest,
  TokenResponse,
} from '@openid/appauth';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { REACT_APP_API_URL } from 'react-native-dotenv';
import config from '../config';
import * as settings from '../state/preferences';
import { sessionSubject } from '../state/sessionObservable';
import { noopHandler } from '../utils/errorHandlers';

export const SERVICE_URI =
  process.env['REACT_APP_API_URL'] ||
  REACT_APP_API_URL ||
  'http://localhost:4000';

interface AuthHeader {
  authorization?: string;
  organization?: string;
  venue?: string;
  store?: string;
  device?: string;
  app?: App;
}

export const tokenLookup = async (): Promise<AuthHeader> => {
  const session = await settings.getSession();

  return {
    organization: session?.currentOrganization?.id || '',
    venue: session?.currentVenue?.id || '',
    store: session?.currentStore?.id || '',
    authorization: session?.token || '',
    device: session?.device?.id || '',
    app: session?.activeApp,
  };
};

const isLoginMutation = (request: GraphQLRequest) =>
  request.operationName === 'cdsLoginByDeviceCode';

/* istanbul ignore file */
export const withToken = setContext((request, { headers }) => {
  return isLoginMutation(request)
    ? headers
    : tokenLookup().then(tokenHeaders => ({
        headers: {
          ...tokenHeaders,
          ...headers,
        },
      }));
});

export const clearSession = async () => {
  await settings.setSession({ authorized: false });
  sessionSubject.next({ authorized: false });
};

/**
 * This will only validate or handle refresh token related errors
 */
export const refreshSession = new TokenRefreshLink({
  isTokenValidOrUndefined: async () => {
    const session = await settings.getSession();
    if (
      session?.token &&
      session?.expiredDate &&
      session?.expiredDate > Date.now()
    ) {
      return true;
    }
    return false;
  },
  fetchAccessToken: async () => {
    const session = await settings.getSession();
    const tokenRequest = new TokenRequest({
      client_id: config.auth0.clientId,
      redirect_uri: config.auth0.redirectUrl,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      refresh_token: session?.refreshToken,
    });
    const issuerConfig =
      await AuthorizationServiceConfiguration.fetchFromIssuer(
        config['auth0'].issuer || '',
        new FetchRequestor(),
      );
    return new BaseTokenRequestHandler(
      new FetchRequestor(),
    ).performTokenRequest(
      issuerConfig,
      tokenRequest,
    ) as unknown as Promise<Response>;
  },
  handleResponse: () => async (response: Response) => {
    const { accessToken, refreshToken } = response as unknown as TokenResponse;
    const oldSession = await settings.getSession();

    const accessTokenPayload = jwt_decode<{
      exp: number;
    }>(accessToken);

    // update new access token
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newTokenPayload: any = {
      token: accessToken as string,
      refreshToken: refreshToken as string,
      expiredDate: accessTokenPayload.exp * 1000,
    };
    sessionSubject.next({ ...sessionSubject.value, ...newTokenPayload });
    await settings.setSession({
      ...oldSession,
      ...newTokenPayload,
      expiredDate: accessTokenPayload.exp * 1000,
    });
    return {
      access_token: accessToken,
    };
  },
  handleFetch: noopHandler,
  handleError: async error => {
    // logout user
    console.error('FAIL TO REFRESH USER TOKEN', error);
    await clearSession();
  },
});

const MAX_RETRY = 100;
const DELAY_UNIT = 1000 * 60;
const NETWORK_REQUEST_FAIL = 'network request failed';
export const syncEventsRetryLink = new RetryLink({
  attempts: (count, operation, error) => {
    if (operation.operationName !== 'syncEvents') return false;
    if ((error.message as string).toLowerCase() !== NETWORK_REQUEST_FAIL)
      return false;
    if (count > MAX_RETRY) return false;
    return true;
  },
  delay: count => {
    if (count < 10) return DELAY_UNIT;
    if (count < 50) return DELAY_UNIT * 5;
    return 10 * DELAY_UNIT;
  },
});

export const authFlowLink = [refreshSession, withToken, syncEventsRetryLink];
