/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { v4 } from 'uuid';
declare const window: any;

const FINGERPRINT_KEY = 'FINGERPRINT_KEY';

export function useDeviceId(): {
  deviceId: string;
  error?: string;
} {
  const [id, setId] = useState(() =>
    Platform.select({
      web: window.localStorage?.getItem?.(FINGERPRINT_KEY),
      default: '',
    }),
  );
  const [error, setError] = useState<any>();
  const createFingerPrint = useCallback(() => {
    let murmur = window.localStorage?.getItem?.(FINGERPRINT_KEY);
    if (!murmur) {
      murmur = v4();
    }
    setId(murmur);
    window.localStorage?.setItem?.(FINGERPRINT_KEY, murmur);
  }, []);

  useEffect(() => {
    let fingerPrintTimeOut: any;
    try {
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        DeviceInfo.getUniqueId().then(uniqueId => {
          setId(uniqueId);
        });
      } else {
        createFingerPrint();
      }
    } catch (e) {
      setError(e);
    }
    return () => {
      fingerPrintTimeOut && clearTimeout(fingerPrintTimeOut);
    };
  }, [createFingerPrint]);

  return useMemo(
    () => ({
      deviceId: id,
      error,
    }),
    [id, error],
  );
}
