import { gql } from '@apollo/client';

export const CREATE_CUSTOMER_DISPLAY = gql`
  mutation createCustomerDisplayOnDevice($input: CreateCustomerDisplayInput!) {
    createCustomerDisplayOnDevice(input: $input) {
      id
      name
      deviceUuid
      isPaired
      connectedPosDevice {
        deviceProfile {
          orgLogo
          backgroundImage
          promoImages
        }
      }
      deviceCode
      lastLoginAt
    }
  }
`;

export const FORGET_CUSTOMER_DISPLAY = gql`
  mutation forgetCustomerDisplayOnDevice($input: ForgetCustomerDisplayInput!) {
    forgetCustomerDisplayOnDevice(input: $input)
  }
`;
