import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { ScreenName } from '../common/enum';
import { CartProvider } from '../hooks/cart/CartProvider';
import { useDeviceEvent } from '../hooks/device/useDeviceEvent';
import { useCartSwitcherEffect } from '../hooks/useCartNavigationHelper/useCartSwitcherEffect';
import CartScreen from '../screens/CartScreen/CartScreen';
import IdleScreen from '../screens/IdleScreen/IdleScreen';
import PaymentScreen from '../screens/PaymentScreen/PaymentScreen';
import CustomerInputScreen from '../screens/CustomerInputScreen/CustomerInputScreen';
import { Platform } from 'react-native';

export interface CartSwitcherProps {
  children: React.ReactNode;
}

const Stack = createStackNavigator();

export const CartSwitcherController: React.FC<CartSwitcherProps> = ({
  children,
}) => {
  useCartSwitcherEffect();
  useDeviceEvent();
  return <>{children}</>;
};

export const CartNavigator: React.FC = () => {
  return (
    <CartProvider>
      <CartSwitcherController>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
            animationEnabled: Platform.OS !== 'android',
          }}
        >
          <Stack.Screen
            component={IdleScreen}
            name={ScreenName.IDLE}
            options={{ title: ScreenName.IDLE }}
          />
          <Stack.Screen
            component={CartScreen}
            name={ScreenName.CART}
            options={{ title: ScreenName.CART }}
          />
          <Stack.Screen
            component={PaymentScreen}
            name={ScreenName.PAYMENT}
            options={{ title: ScreenName.PAYMENT }}
          />
          <Stack.Screen
            component={CustomerInputScreen}
            name={ScreenName.INPUT_INFO}
            options={{ title: ScreenName.INPUT_INFO }}
          />
        </Stack.Navigator>
      </CartSwitcherController>
    </CartProvider>
  );
};
