import { RenderProps, StyleFn } from '@oolio-group/domain';
import React from 'react';
import { FelaComponent } from 'react-fela';
import { Text, View, ViewStyle } from 'react-native';
import IconButton from '../Button/IconButton';

export interface NotificationProps {
  error?: boolean;
  info?: boolean;
  warning?: boolean;
  message: string;
  success?: boolean;
  onClose?: () => void;
  containerStyle?: ViewStyle;
}

const notificationStyle: StyleFn = ({ theme, error, success, info }) => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: 50,
  alignItems: 'center',
  paddingHorizontal: theme.padding.large,
  backgroundColor: error
    ? theme.colors.danger
    : success
    ? theme.colors.success
    : info
    ? theme.colors.secondary
    : theme.colors.background,
});

const textStyle: StyleFn = ({ theme, error, success, info }) => ({
  flex: 1,
  textAlign: 'center',
  letterSpacing: -0.5,
  color: error || success || info ? theme.colors.white : theme.colors.paragraph,
});

interface MessageProps {
  text: string;
}

const Message: React.FC<MessageProps> = ({ text, ...props }) => (
  <FelaComponent style={textStyle} {...props}>
    {({ style }: RenderProps): React.ReactNode => (
      <Text style={style}>{text}</Text>
    )}
  </FelaComponent>
);

interface CloseButtonProps {
  onPress?: () => void;
  error?: boolean;
  success?: boolean;
  info?: boolean;
}

const closeButtonStyle: StyleFn = ({ theme, error, success, info }) => ({
  color: error || success || info ? theme.colors.white : theme.colors.paragraph,
});

const CloseButton: React.FC<CloseButtonProps> = ({ onPress, ...props }) => (
  <FelaComponent style={closeButtonStyle} {...props}>
    {({ style }: RenderProps): React.ReactNode => (
      <IconButton
        icon="times-circle"
        onPress={onPress}
        testID="closeNotification"
        iconStyle={style}
        iconSize={20}
      />
    )}
  </FelaComponent>
);

const Notification: React.FC<NotificationProps> = ({
  message,
  onClose,
  containerStyle,
  ...props
}: NotificationProps) => {
  return (
    <FelaComponent style={notificationStyle} {...props}>
      {({ style }: RenderProps): React.ReactNode => (
        <View style={[style, containerStyle]}>
          <Message text={message} {...props} />
          <CloseButton onPress={onClose} {...props} />
        </View>
      )}
    </FelaComponent>
  );
};

export default Notification;
