import { Locale, supportedLocales } from '@oolio-group/localization';
import * as RNLocalize from 'react-native-localize';
import * as storage from '../storage/interface';
import { Session } from './Session';

export const LOCALE_KEY = 'preferredLocale';
export const SESSION_KEY = 'session';
export const THEME_KEY = 'preferredTheme';
export const ORDER_EVENTS_QUEUE_KEY = 'eventsQueue';

const availableLocales = Object.keys(supportedLocales);

export async function getLocale(): Promise<Locale | undefined> {
  try {
    const savedLocale = await storage.getItem<Locale>(LOCALE_KEY);

    if (savedLocale && savedLocale.languageTag) {
      return savedLocale;
    }

    const devicePreference =
      RNLocalize.findBestAvailableLanguage(availableLocales);

    return devicePreference as unknown as Locale;
  } catch (e) {}

  return undefined;
}

export async function setLocale(locale: Locale) {
  try {
    storage.setItem(LOCALE_KEY, locale);
  } catch (e) {}
}

export async function getSession(): Promise<Session | undefined> {
  try {
    const session = await storage.getItem<Session>(SESSION_KEY);

    return session || { authorized: false };
  } catch (e) {}

  return undefined;
}

export async function setSession(data: Session) {
  try {
    storage.setItem(SESSION_KEY, data);
  } catch (e) {}
}

export async function getTheme(): Promise<string | undefined> {
  try {
    return await storage.getItem<string>(THEME_KEY);
  } catch (e) {}

  return undefined;
}

export async function setTheme(data: string) {
  try {
    storage.setItem(THEME_KEY, data);
  } catch (e) {}
}
