import { BehaviorSubject, Observable } from 'rxjs';
import { SubscriptionState } from '@oolio-group/gql-client';

export class SubscriptionStateUtil {
  private subject = new BehaviorSubject<SubscriptionState>(
    SubscriptionState.CLOSED,
  );

  updateState = (state: SubscriptionState) => {
    this.subject.next(state);
  };

  get getSubscriptionState$(): Observable<SubscriptionState> {
    return this.subject.asObservable();
  }
}

export const subscriptionStateUtil = new SubscriptionStateUtil();
