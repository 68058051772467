import React from 'react';
import Icon from '../Icon/Icon';
import theme from '../../common/theme';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useCurrency, useTranslation } from '@oolio-group/localization';

export interface PaymentStatusViewProps {
  status: 'awaiting' | 'error';
  testID?: string;
  amountDue: number;
  onPress: () => void;
}

const PaymentStatusView: React.FC<PaymentStatusViewProps> = ({
  status,
  amountDue,
  testID,
  onPress,
}) => {
  const { formatCurrency } = useCurrency();
  const { translate } = useTranslation();
  const isError = status === 'error';
  return (
    <View testID={testID} style={styles.innerView}>
      <View style={isError ? styles.boxError : styles.boxView}>
        <Icon
          name={isError ? 'UilExclamationTriangle' : 'UilClock'}
          size={80}
          color={theme.colors.white}
        />
        <Text style={styles.title}>
          {isError
            ? translate('cdsPaymentScreen.paymentError')
            : translate('cdsPaymentScreen.paymentAwaiting')}
        </Text>
        <Text style={styles.price}>{formatCurrency(amountDue)}</Text>
        <Text style={styles.title}>
          {translate('cdsPaymentScreen.amountDue')}
        </Text>
      </View>
      <TouchableOpacity testID="button" onPress={onPress}>
        <Text style={styles.largeText}>
          {isError
            ? translate('cdsPaymentScreen.tryAgain', { arrow: '→' })
            : translate('cdsPaymentScreen.swipeToPay')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  innerView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxView: {
    width: 420,
    padding: theme.spacing.xl,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.states.neutral,
  },
  boxError: {
    width: 420,
    padding: theme.spacing.xl,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.states.negative,
  },
  title: {
    lineHeight: 26,
    fontWeight: '600',
    color: theme.colors.white,
    fontSize: theme.fontSize.large,
    marginTop: theme.spacing.small,
  },
  price: {
    fontSize: 48,
    marginTop: theme.spacing.large,
    fontWeight: '600',
    color: theme.colors.white,
  },
  largeText: {
    fontSize: theme.fontSize.xl,
    lineHeight: 60,
    fontWeight: '700',
    alignSelf: 'center',
    color: theme.colors.white,
    marginTop: theme.spacing.large,
  },
});

export default PaymentStatusView;
