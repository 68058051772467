import { StyleSheet, ViewStyle, TextStyle } from 'react-native';
import theme from '../../common/theme';

export const styles = StyleSheet.create({
  btn: {
    height: 44,
    minWidth: 100,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.small,
    backgroundColor: theme.colors.grey1,
  },
  btnIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.small,
  },
  btnText: {
    color: theme.colors.grey6,
    textTransform: 'uppercase',
  },
  textButton: {
    lineHeight: 28,
    textAlign: 'center',
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
});

export const getContainerStyle = (
  type: string | undefined,
  isIcon?: boolean,
) => {
  const containerStyles: ViewStyle[] = isIcon ? [styles.btnIcon] : [styles.btn];

  switch (type) {
    case 'primary':
      containerStyles.push({ backgroundColor: theme.colors.primary });
      break;
    case 'primaryLight':
      containerStyles.push({ backgroundColor: theme.colors.primaryLight });
      break;
    case 'positive':
      containerStyles.push({ backgroundColor: theme.colors.green });
      break;
    case 'negative':
      containerStyles.push({ backgroundColor: theme.colors.red });
      break;
    case 'neutral':
      containerStyles.push({ backgroundColor: theme.colors.blue });
      break;
    case 'focus':
      containerStyles.push({ backgroundColor: theme.colors.orange });
      break;
    case 'positiveLight':
      containerStyles.push({ backgroundColor: theme.colors.greenLight });
      break;
    case 'negativeLight':
      containerStyles.push({ backgroundColor: theme.colors.redLight });
      break;
    case 'neutralLight':
      containerStyles.push({ backgroundColor: theme.colors.blueLight });
      break;
    case 'focusLight':
      containerStyles.push({ backgroundColor: theme.colors.amberLight });
      break;
    case 'interface':
      containerStyles.push({ backgroundColor: theme.colors.teritiary3 });
      break;
    default:
      containerStyles.push({ backgroundColor: theme.colors.grey1 });
      break;
  }

  return containerStyles;
};

export const getLabelStyle = (
  type: string | undefined,
  uppercase?: boolean,
) => {
  const labelStyles: TextStyle[] = [styles.textButton];

  if (uppercase) {
    labelStyles.push({ textTransform: 'uppercase' });
  }

  if (type === 'primaryLight') {
    labelStyles.push({ color: theme.colors.primary });
  } else if (type === 'positiveLight') {
    labelStyles.push({ color: theme.colors.green });
  } else if (type === 'negativeLight') {
    labelStyles.push({ color: theme.colors.red });
  } else if (type === 'neutralLight') {
    labelStyles.push({ color: theme.colors.blue });
  } else if (type === 'focusLight') {
    labelStyles.push({ color: theme.colors.orange });
  } else if (type === 'disabled') {
    labelStyles.push({ color: theme.colors.grey5 });
  } else if (type === 'cancel') {
    labelStyles.push({ color: theme.colors.black1 });
  } else if (type === 'teal') {
    labelStyles.push({ color: theme.colors.teal });
  } else {
    labelStyles.push({ color: theme.colors.white });
  }

  return labelStyles;
};

export const getIconColor = (type: string | undefined) => {
  switch (type) {
    case 'primary':
    case 'primaryLight':
    case 'positive':
    case 'negative':
    case 'neutral':
    case 'focus':
    case 'interface':
      return theme.colors.white;
    case 'positiveLight':
      return theme.colors.green;
    case 'negativeLight':
      return theme.colors.red;
    case 'neutralLight':
      return theme.colors.blue;
    case 'focusLight':
      return theme.colors.orange;
    case 'cancel':
      return theme.colors.black1;
    case 'disabled':
      return theme.colors.grey4;
    default:
      return theme.colors.grey6;
  }
};

export default {
  styles,
  getContainerStyle,
  getLabelStyle,
};
