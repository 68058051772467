import { useEffect, useRef } from 'react';
import { BehaviorSubject } from 'rxjs';
import { ONE_SECOND } from '../../common/const';

export const globalIntervalObserver = new BehaviorSubject<number>(0);

export const useGlobalInterval = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const id = useRef<any>();
  useEffect(() => {
    id.current = setInterval(() => {
      globalIntervalObserver.next(Date.now());
    }, ONE_SECOND);
    return () => {
      clearInterval(id.current);
    };
  }, []);
};
