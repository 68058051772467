import { useSubscription } from '@apollo/client/react/hooks';
import { revertConflictingFields } from '@oolio-group/client-utils';
import { Order, OrderAction, OrderEvent } from '@oolio-group/domain';
import { orderEventsHandler } from '../../utils/orderEventsHandler';
import { useEffect, useRef, useState } from 'react';
import { observeOn, queueScheduler } from 'rxjs';
import { CDS_ORDER_EVENT } from '../../graphql/subscription';
import { sessionSubject } from '../../state/sessionObservable';
import useBehaviorSubjectState from '../rxjs/useSubjectState';
import { cdsOrderEventsSubject } from './cdsOrderEventsObservable';

const useCartEvent = () => {
  const [order, setOrder] = useState<Order>({} as Order);
  // used to calculate new order data based. changing preOrder won't make component rerender
  const preOrder = useRef<Order>({} as Order);
  const orderEvents = useRef<OrderEvent[]>([]);
  const { value: session } = useBehaviorSubjectState(sessionSubject);

  useSubscription(CDS_ORDER_EVENT, {
    variables: {
      cdsId: session.customerDisplay?.id,
      deviceId: session?.device?.id,
      lastActiveTime: 0,
      orderId: order.id,
    },
    onSubscriptionData: ({ subscriptionData }) => {
      cdsOrderEventsSubject.next(subscriptionData.data?.['cdsOrderEvents']);
    },
  });

  useEffect(() => {
    const sub = cdsOrderEventsSubject
      .pipe(observeOn(queueScheduler))
      .subscribe((events: OrderEvent[]) => {
        if (!events.length) return;

        let eventArray = revertConflictingFields(events);
        const isCloseOrder = eventArray.some(
          event => event.action === OrderAction.ORDER_CLOSE,
        );
        if (isCloseOrder) {
          preOrder.current = {} as Order;
          setOrder({} as Order);
          orderEvents.current = [];
          return;
        }

        const isSameOrder = eventArray.every(
          event => event.orderId === preOrder.current.id,
        );
        if (
          !isSameOrder &&
          !(
            eventArray.length === 1 &&
            eventArray[0].action === OrderAction.ORDER_INITIATE
          )
        ) {
          const lastOrderId = eventArray[eventArray.length - 1].orderId;
          eventArray = eventArray.filter(
            event => event.orderId === lastOrderId,
          );
          orderEvents.current = [];
        }

        orderEvents.current = orderEvents.current.concat(eventArray);

        const newOrder = orderEventsHandler(orderEvents.current, {} as Order);
        setOrder(newOrder);
        preOrder.current = newOrder;
      });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return {
    order,
  };
};

export default useCartEvent;
