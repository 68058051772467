import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import {
  CreateCustomerRequest,
  Customer,
  CustomerAccountDetails,
  UpdateCustomerRequest,
} from '@oolio-group/domain';
import { useCallback, useState } from 'react';
import {
  CREATE_CUSTOMER,
  GET_CUSTOMER,
  UPDATE_CUSTOMER,
} from '../../graphql/customer';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';

export interface CustomerAddress {
  line1: string;
  line2?: string;
  city?: string;
  suburb: string;
  state?: string;
  postalCode: string;
  country?: string;
}

export interface UpdateCustomerBalanceRequest {
  id: string;
  customerAccountDetails?: CustomerAccountDetails;
}

export interface UseCustomersProps {
  createCustomer: (input: CreateCustomerRequest) => Promise<void>;
  updateCustomer: (input: UpdateCustomerRequest) => Promise<void>;
  getCustomerById: (id: string) => void;
  loading: boolean;
  error: string | undefined;
  customerMaps: Record<string, Customer>;
}

export function useCustomers(): UseCustomersProps {
  const [customerMaps, setCustomerMaps] = useState<Record<string, Customer>>(
    {},
  );
  const [getCustomerByIdRequest, getCustomerByIdResponse] = useLazyQuery<{
    customerById: Customer;
  }>(GET_CUSTOMER, {
    fetchPolicy: 'cache-and-network',
    onError: noopHandler,
    onCompleted: response => {
      const customer = response.customerById;
      setCustomerMaps(pre => ({ ...pre, [customer.id]: customer }));
    },
  });

  const [createCustomerReq, createCustomerRes] = useMutation(CREATE_CUSTOMER, {
    onError: noopHandler,
  });

  const getCustomerById = useCallback(
    (id: string) => {
      getCustomerByIdRequest({
        variables: {
          id: id,
        },
      });
    },
    [getCustomerByIdRequest],
  );

  const createCustomer = useCallback(
    async (input: CreateCustomerRequest): Promise<void> => {
      createCustomerReq({
        variables: {
          input,
        },
      });
    },
    [createCustomerReq],
  );

  const [updateCustomerReq, updateCustomerRes] = useMutation<{
    updateCustomer: Customer;
  }>(UPDATE_CUSTOMER, {
    onError: noopHandler,
  });

  const updateCustomer = useCallback(
    async (input: UpdateCustomerRequest): Promise<void> => {
      await updateCustomerReq({
        variables: { input },
      });
    },
    [updateCustomerReq],
  );

  const RESPONSE_ENTITIES = [
    getCustomerByIdResponse,
    createCustomerRes,
    updateCustomerRes,
  ];

  const loading = isLoading(RESPONSE_ENTITIES);
  const _error = getError(RESPONSE_ENTITIES);

  return {
    getCustomerById,
    createCustomer,
    updateCustomer,
    loading,
    error: _error ? parseApolloError(_error) : undefined,
    customerMaps,
  };
}
