import { Platform } from 'react-native';
import { Storage } from './Storage';

export function getPreferredStorage(): Storage {
  switch (Platform.OS) {
    case 'web':
      return window.localStorage as Storage;

    default:
      return require('@react-native-async-storage/async-storage').default;
  }
}
