import { gql } from 'graphql-tag';

export const GET_DEVICES = gql`
  query devices {
    devices {
      id
      name
      store {
        id
      }
      deviceProfile {
        id
        name
        isDefault
        orderTypes {
          id
          name
          code
        }
        defaultOrderType {
          id
          name
          code
        }
      }
      customerDisplay {
        id
        name
        isPaired
        lastLoginAt
      }
      mode
    }
  }
`;
