import { gql } from 'graphql-tag';

export const GET_VENUES = gql`
  query venues {
    venues {
      id
      name
      email
      phone
      stores {
        id
        name
      }
    }
  }
`;
