import { gql } from '@apollo/client';

export const CUSTOMER_ACCOUNT_FRAGMENT = `
    id
    firstName
    lastName
    name
    email
    phone
    phoneNumber
    preferredAddress {
      line1
      suburb
      city
      postalCode
      isoCountryCode
    }
    customerAccountDetails {
      maxOrderLimit
      maxBalanceLimit
      currentBalance
      accountPayment
    }
    loyaltyMember
    loyaltyPointsBalance
    lifetimeLoyaltyPoints
    createdAt
    enrolledAt
    loyaltyEnrolmentSource
  `;

export const GET_CUSTOMER = gql`
  query customerById($id: ID!){
    customerById(id: $id){
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;
