import { createRenderer } from 'fela-native';
import React from 'react';
import { RendererProvider } from 'react-fela';
import { AppRegistry, Platform } from 'react-native';
import { enableScreens } from 'react-native-screens';
require('react-native-gesture-handler');

enableScreens();

// eslint-disable-next-line @typescript-eslint/no-var-requires
const App = require('./App').default;
const renderer = createRenderer();

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const wrappedApp = () => (
  <RendererProvider renderer={renderer}>
    <App />
  </RendererProvider>
);

export { wrappedApp as App };

AppRegistry.registerComponent('OolioCDS', () => wrappedApp);

if (Platform.OS === 'web') {
  AppRegistry.runApplication('OolioCDS', {
    rootTag: document.getElementById('root'),
  });
}
