import { useQuery } from '@apollo/client/react/hooks';
import { GET_ORGANIZATION_DETAILS } from '../../graphql/organization';

export const useOrganization = (orgId: string) => {
  const { data, loading, error } = useQuery(GET_ORGANIZATION_DETAILS, {
    variables: {
      orgId,
    },
    fetchPolicy: 'no-cache',
  });

  return {
    organization: data?.getOrganizationById,
    loading,
    error,
  };
};
