import { useFocusEffect, useRoute } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { useCart } from '../../hooks/cart/CartProvider';
import React, { useCallback, useMemo, useRef } from 'react';
import { TEN_SECOND } from '../../common/const';
import { ScreenName } from '../../common/enum';
import { Background } from '../../component/Background/Background';
import CompletePaymentView from '../../component/PaymentStatusView/CompletePaymentView';
import PaymentStatusView from '../../component/PaymentStatusView/PaymentStatusView';
import useBehaviorSubjectState from '../../hooks/rxjs/useSubjectState';
import { useCartNavigationHelper } from '../../hooks/useCartNavigationHelper/useCartNavigationHelper';
import { useCartSetActiveScreen } from '../../hooks/useCartNavigationHelper/useCartSwitcherEffect';
import { sessionSubject } from '../../state/sessionObservable';

export type PaymentScreenParam = StackScreenProps<
  {
    [ScreenName.PAYMENT]: {
      error?: boolean;
      awaiting?: boolean;
      changeDue?: number;
      amount?: number;
      paymentRequestId?: number;
    };
  },
  ScreenName.PAYMENT
>;

const PaymentScreen: React.FC = () => {
  const { navigateIdleScreen } = useCartNavigationHelper();
  const { value: session } = useBehaviorSubjectState(sessionSubject);
  const { params: routeParams } = useRoute<PaymentScreenParam['route']>();
  const { order } = useCart();
  const cachedPaymentAmount = useRef(0);
  useCartSetActiveScreen(ScreenName.PAYMENT, routeParams);
  const {
    error = false,
    awaiting = false,
    changeDue = 0,
    amount = 0,
    paymentRequestId = '',
  } = routeParams ?? {};

  const amountByPayment = useMemo(() => {
    const payment = order.payments?.find(
      payment => payment.paymentRequestId === paymentRequestId,
    );
    const paid = payment ? payment.tendered : 0;
    // try to cache paid value for a brief moment because the screen may display but shared order state already changed
    if (paid) {
      cachedPaymentAmount.current = paid;
    }
    return paid;
  }, [paymentRequestId, order]);

  useFocusEffect(
    useCallback(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let timeoutId: any;
      if (!awaiting && !error) {
        timeoutId = setTimeout(() => {
          navigateIdleScreen();
        }, TEN_SECOND);
      }
      return () => {
        timeoutId && clearTimeout(timeoutId);
      };
    }, [navigateIdleScreen, awaiting, error]),
  );

  return (
    <Background session={session}>
      {awaiting || error ? (
        <PaymentStatusView
          testID=""
          status={awaiting ? 'awaiting' : 'error'}
          amountDue={amount || cachedPaymentAmount.current}
          onPress={navigateIdleScreen}
        />
      ) : (
        <CompletePaymentView
          changeDue={changeDue ?? 0}
          amount={
            (amount || amountByPayment || cachedPaymentAmount.current) ?? 0
          }
        />
      )}
    </Background>
  );
};

export default PaymentScreen;
