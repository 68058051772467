import { useLazyQuery } from '@apollo/client/react/hooks';
import { useEffect } from 'react';
import { GET_VENUES } from '../../graphql/venues';

export const useVenues = () => {
  const [refetch, { data, loading, error }] = useLazyQuery(GET_VENUES, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return {
    venues: data?.venues,
    loading,
    error,
    refetch,
  };
};
