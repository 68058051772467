import { useMutation } from '@apollo/client/react/hooks';
import { CreateCustomerDisplayInput } from '@oolio-group/domain';
import { useCallback } from 'react';
import { CREATE_CUSTOMER_DISPLAY } from '../../graphql/customerDisplay';

export function useCustomerDisplay() {
  const [createCdsOnDevice, cdsResponse] = useMutation(CREATE_CUSTOMER_DISPLAY);

  const onCreateCdsOnDevice = useCallback(
    async (input: CreateCustomerDisplayInput) => {
      return await createCdsOnDevice({
        variables: {
          input: {
            deviceId: input.deviceId,
            name: input.name,
            deviceUuid: input.deviceUuid,
            isPaired: input.isPaired,
          } as CreateCustomerDisplayInput,
        },
      });
    },
    [createCdsOnDevice],
  );

  return {
    createCdsOnDevice: onCreateCdsOnDevice,
    error: cdsResponse?.error,
    customerDisplay: cdsResponse.data?.createCustomerDisplayOnDevice,
    loading: cdsResponse.loading,
  };
}
