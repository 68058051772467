import { orderUpdateEventsValidation } from '@oolio-group/client-utils';
import { Order, OrderAction, OrderEvent } from '@oolio-group/domain';
import { computeOrderState } from '@oolio-group/order-helper';

export const orderEventsHandler = (events: OrderEvent[], order: Order) => {
  const beginEvent = events[0];
  const isRefundOrder = beginEvent.action === OrderAction.ORDER_REFUND_INITIATE;
  let eventsNotProcessed;
  if (order?.prevEventId) {
    const nextEventToProcessIndex = events.findIndex(
      x => x.previous === order?.prevEventId,
    );
    if (nextEventToProcessIndex === -1) {
      eventsNotProcessed = events;
    } else {
      eventsNotProcessed = events.slice(nextEventToProcessIndex);
    }
  } else {
    eventsNotProcessed = events;
  }

  const isValid: boolean = isRefundOrder
    ? true
    : orderUpdateEventsValidation(eventsNotProcessed, order?.prevEventId || '');
  if (isValid) {
    const computedOrder = order
      ? computeOrderState(eventsNotProcessed, order)
      : computeOrderState(eventsNotProcessed);
    return computedOrder;
  }
  return order;
};
