import React from 'react';
import { ScreenName } from '../../common/enum';
import { Background } from '../../component/Background/Background';
import useBehaviorSubjectState from '../../hooks/rxjs/useSubjectState';
import { useCartSetActiveScreen } from '../../hooks/useCartNavigationHelper/useCartSwitcherEffect';
import { sessionSubject } from '../../state/sessionObservable';

const IdleScreen: React.FC = () => {
  useCartSetActiveScreen(ScreenName.IDLE);
  const { value: session } = useBehaviorSubjectState(sessionSubject);

  return <Background session={session} />;
};

export default IdleScreen;
