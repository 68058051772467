import { useCurrency, useTranslation } from '@oolio-group/localization';
import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import theme from '../../common/theme';
import Icon from '../Icon/Icon';
import { useCartNavigationHelper } from '../../hooks/useCartNavigationHelper/useCartNavigationHelper';
import styles from './CompletePaymentView.styles';

export interface CompletePaymentViewProps {
  testID?: string;
  amount?: number;
  changeDue?: number;
}

const CompletePaymentView: React.FC<CompletePaymentViewProps> = ({
  testID = 'complete-payment-view',
  amount = 0,
  changeDue = 0,
}) => {
  const { formatCurrency } = useCurrency();
  const { translate } = useTranslation();
  const { navigateInputInfoScreen, navigateIdleScreen } =
    useCartNavigationHelper();
  return (
    <View testID={testID} style={styles.innerView}>
      <View style={styles.paymentInfo}>
        <Icon name={'UilCheckCircle'} size={80} color={theme.colors.white} />
        <Text style={styles.title}>
          {translate('cdsPaymentScreen.paymentComplete')}
        </Text>
        <Text style={styles.price} testID="payment-amount">
          {formatCurrency(amount)}
        </Text>
        <Text style={styles.amountPaid}>
          {translate('cdsPaymentScreen.amountPaid')}
        </Text>
        {changeDue === 0 ? (
          <Text style={styles.changeDue} testID="payment-changeDue">
            {translate('cdsPaymentScreen.noChangeDue')}
          </Text>
        ) : (
          <Text style={styles.changeDue} testID="payment-changeDue">
            {translate('cdsPaymentScreen.changeDue', {
              value: formatCurrency(changeDue),
            })}
          </Text>
        )}
      </View>
      <View style={styles.receiptOptions}>
        <View style={styles.receiptOptionsBox}>
          <Text style={styles.receiptOptionsTitle}>
            {translate('cdsPaymentScreen.receiptReceiveQuestion')}
          </Text>
          <TouchableOpacity
            testID="button"
            style={styles.btnSmsOption}
            onPress={() => navigateInputInfoScreen('sms')}
          >
            <Text style={styles.optionText}>
              {translate('cdsPaymentScreen.smsOption')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            testID="button"
            style={styles.btnEmailOption}
            onPress={() => navigateInputInfoScreen('email')}
          >
            <Text style={styles.optionText}>
              {translate('cdsPaymentScreen.emailOption')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            testID="button"
            style={styles.btnNoReceipt}
            onPress={navigateIdleScreen}
          >
            <Text style={styles.noReceiptText}>
              {translate('cdsPaymentScreen.noThank')}
            </Text>
            <Icon name={'UilArrowRight'} size={30} color={theme.colors.white} />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default CompletePaymentView;
