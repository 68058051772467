import { RenderProps, StyleFn } from '@oolio-group/domain';
import React from 'react';
import { FelaComponent } from 'react-fela';
import { View } from 'react-native';
import { useNotification } from '@oolio-group/hooks';
import NotificationComponent from './Notification';

const notificationStyle: StyleFn = () => ({
  top: 0,
  width: '100%',
  zIndex: 20,
  position: 'absolute',
});

export const NotificationList: React.FC = () => {
  const { notifications, closeNotification } = useNotification();
  return (
    <FelaComponent style={notificationStyle}>
      {({ style }: RenderProps): React.ReactNode => (
        <View style={style}>
          {notifications.length > 0
            ? notifications.map((notification, index) => (
                <NotificationComponent
                  key={index}
                  {...notification}
                  onClose={closeNotification.bind(null, notification.id)}
                />
              ))
            : null}
        </View>
      )}
    </FelaComponent>
  );
};

NotificationList.displayName = 'NotificationList';
