export const orders = [
  {
    id: '30d1a0f2-cdbc-4431-b30a-623f42d794a1',
    createdAt: 1587822467,
    deviceProfile: null,
    createdBy: {
      id: '46bade48-6a49-4d94-a8d2-05ce32379318',
      name: 'Mrs. Tess Reinger',
    },
    status: 'completed',
    orderNumber: 'R1-001',
    orderType: {
      id: '9c235f4f-6811-4964-8b08-c5e777013726',
      name: 'Takeaway',
      code: 'TA',
    },
    orderNote: null,
    orderItems: [
      {
        id: 'abced-811c-4ba4-bc1a-3d4694858b13',
        unitPrice: 20,
        product: {
          id: '1',
          name: 'Eggs on Toast',
        },
        quantity: 2,
        notes: '* Some note',
        price: '$0.00',
        modifiers: ['Fried'],
        taxes: [
          {
            id: '1483abda-b18c-436a-ba65-2a55bc1de829',
            name: 'GST',
          },
        ],
      },
      {
        id: 'abced-811c-4ba4-bc1a-3d4694258b14',
        unitPrice: 20,
        product: {
          id: '1',
          name: 'Banana Bread',
        },
        quantity: 1,
        notes: null,
        price: '$0.00',
        modifiers: ['Hollandaise'],
        taxes: [
          {
            id: '1483abda-b18c-436a-ba65-2a55bc1de829',
            name: 'GST',
          },
        ],
      },
      {
        id: 'abced-811c-4ba4-bc1a-3d4694258b15',
        unitPrice: 20,
        product: {
          id: '2',
          name: 'Cappuccino',
        },
        quantity: 1,
        notes: null,
        price: '$0.00',
        modifiers: ['Small', 'Soy Milk', 'Extra Hot'],
        taxes: [
          {
            id: '1483abda-b18c-436a-ba65-2a55bc1de829',
            name: 'GST',
          },
        ],
      },
    ],
    customer: {
      firstName: 'John',
      lastName: 'Bendigo',
      email: 'john.b@gmail.com',
      customerAccountDetails: {
        maxBalanceLimit: 1000,
        currentBalance: 1000,
        maxOrderLimit: 1000,
        accountPayment: true,
      },
    },
    payments: [
      {
        id: 'cc798f2b-fc24-4454-aac1-5302d1e50086',
        paymentType: {
          id: 'cc798f2b-fc24-4454-aac1-5302d1e50086',
          name: 'Card',
        },
        amount: 23.5,
      },
    ],
    subTotal: 100.25,
    totalTax: 10.03,
    totalPrice: 110.55,
    totalPaymentAmount: 110.55,
    taxes: [
      {
        tax: {
          id: '1483abda-b18c-436a-ba65-2a55bc1de829',
          name: 'GST',
        },
        amount: 1,
      },
    ],
    lastSyncedEventId: '',
    roundingAmount: 0,
  },
  {
    id: '22d794c5-e3ee-441f-bfdd-da0feb715404',
    createdAt: 1587822467,
    deviceProfile: null,
    createdBy: {
      id: '46bade48-6a49-4d94-a8d2-05ce32379318',
      name: 'Mrs. Tess Reinger',
    },
    status: 'completed',
    orderNumber: 'R1-002',
    orderNote: null,
    orderType: {
      id: 'd7574047-cc56-4b0e-b594-2d28a4a4f0b3',
      name: 'Home Delivery',
      code: 'HD',
    },
    taxes: [
      {
        tax: {
          id: '1483abda-b18c-436a-ba65-2a55bc1de829',
          name: 'GST',
        },
        amount: 1,
      },
    ],
    orderItems: [
      {
        id: 'abced-811c-4ba4-bc1a-3d4694858b14',
        unitPrice: 20,
        product: {
          id: '2313241',
          name: 'Beer Batter Fries',
        },
        quantity: 2,
        notes: null,
        price: null,
        modifiers: [
          {
            product: {
              id: '324234',
              name: 'Test',
            },
            quantity: 1,
            unitPrice: 0.5,
          },
          {
            product: {
              id: '324234',
              name: 'Test',
            },
            quantity: 2,
            unitPrice: 2.5,
          },
        ],
        taxes: [
          {
            id: '1483abda-b18c-436a-ba65-2a55bc1de829',
            name: 'GST',
          },
        ],
      },
      {
        id: 'abced-811c-4ba4-bc1a-3d4694258b14',
        unitPrice: 12,
        product: {
          id: 'aaaqe',
          name: 'bulla frozen yoghurt',
        },
        quantity: 1,
        notes: null,
        price: null,
        modifiers: null,
        taxes: [
          {
            id: '1483abda-b18c-436a-ba65-2a55bc1de829',
            name: 'GST',
          },
        ],
      },
    ],
    customer: {
      firstName: 'Mary',
      lastName: 'Lam',
      email: 'mary.lam@gmail.com',
      customerAccountDetails: {
        maxBalanceLimit: 0,
        currentBalance: 0,
        maxOrderLimit: 0,
        accountPayment: true,
      },
    },
    payments: [
      {
        id: 'cc798f2b-fc24-4454-aac1-5302d1e50086',
        paymentType: {
          id: 'cc798f2b-fc24-4454-aac1-5302d1e50086',
          name: 'Card',
        },
        amount: 23.5,
      },
      {
        id: '22d794c5-e3ee-441f-bfdd-da0feb715404',
        paymentType: {
          id: '9f0ee4e8-ece9-40ce-82c1-62d40842cf99',
          name: 'Cash',
        },
        amount: 25,
      },
    ],
    subTotal: 11.5,
    totalTax: 1.15,
    totalPrice: 12.65,
    totalPaymentAmount: 12.65,
    roundingAmount: 0,
    lastSyncedEventId: '',
  },
];

export const ONE_SECOND = 1000;
export const TEN_SECOND = 1000 * 10;
export const ONE_DAY_IN_SECOND = 60 * 60 * 24;
export const CASH_PAYMENT_TYPE = 'cash';
export const CARD_PAYMENT_TYPE = 'card';
export const DUMP_CHANGE = '2';
