/* eslint-disable react-native/no-color-literals */
import * as React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import theme from '../../common/theme';
import { Session } from '../../state/Session';
import Gradient from '../Gradient/Gradient';

export interface BackgroundProps {
  session: Session;
  children?: React.ReactNode;
}

export const Background: React.FC<BackgroundProps> = ({
  children,
  session,
}) => {
  if (
    session?.customerDisplay?.connectedPosDevice?.deviceProfile?.backgroundImage
  ) {
    return (
      <View style={styles.container}>
        <Image
          source={{
            uri: session.customerDisplay?.connectedPosDevice?.deviceProfile
              ?.backgroundImage,
          }}
          style={styles.background}
          resizeMode="cover"
        />
        <View style={styles.blackPanel} />
        {!!children && children}
        {!children &&
          session?.customerDisplay?.connectedPosDevice?.deviceProfile
            ?.orgLogo && (
            <Image
              source={{
                uri: session.customerDisplay?.connectedPosDevice?.deviceProfile
                  ?.orgLogo,
              }}
              style={styles.logoImage}
              resizeMode="contain"
            />
          )}
      </View>
    );
  } else if (
    !session?.customerDisplay?.connectedPosDevice?.deviceProfile
      ?.backgroundImage &&
    session?.customerDisplay?.connectedPosDevice?.deviceProfile?.orgLogo
  ) {
    return (
      <View style={styles.container}>
        <View style={styles.blackPanel} />
        {!!children && children}
        {!children && (
          <Image
            source={{
              uri: session.customerDisplay?.connectedPosDevice?.deviceProfile
                ?.orgLogo,
            }}
            style={styles.logoImage}
            resizeMode="contain"
          />
        )}
      </View>
    );
  }

  return (
    <Gradient
      style={styles.container}
      colors={[theme.colors.pink, theme.colors.deepPurple]}
      start={{ x: 0.0, y: 0.5 }}
      end={{ x: 1, y: 1 }}
    >
      {!!children && children}
      {!children && (
        <View testID="navigate-login-button">
          <Text style={styles.logoText} testID="store-name">
            {session?.currentStore?.name}
          </Text>
        </View>
      )}
    </Gradient>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: theme.deviceHeight,
  },
  background: {
    position: 'absolute',
    width: theme.deviceWidth,
    height: theme.deviceHeight,
    flex: 1,
  },
  blackPanel: {
    position: 'absolute',
    width: theme.deviceWidth,
    height: theme.deviceHeight,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  logoText: {
    fontSize: 38,
    lineHeight: 38,
    fontWeight: 'bold',
    color: theme.colors.white,
  },
  logoImage: {
    width: 140,
    height: 140,
    position: 'absolute',
  },
});
